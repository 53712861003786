<template>
  <div class="bookings">
    <div class="card full-page">
      <div class="card-body inner-scroll">
        <template v-if="hasAccess">
          <div class="feat-desc">
            <p>{{ $t('desc_feat_bookings_1') }}</p>
          </div>
          <div v-if="bookings.length > 0" class="bookings-table">
            <div class="filter-table float-right w-25 mb-2">
              <feather-icon
                style="color: #6bc4c5; display: inline; margin-top: 0.75em"
                size="16"
                icon="SearchIcon"
                class="mr-50 float-left"
              />
              <input
                v-model="search"
                style="display: inline; width: 85% !important"
                type="text"
                class="form-control inline"
                :placeholder="$t('Search')"
              />
            </div>
            <b-table
              striped
              hover
              responsive
              :filter="search"
              class="position-relative"
              :items="bookings"
              :fields="[
                { date: { label: this.$t('Date') } },
                { parent: { label: this.$t('Parent') } },
                { email: { label: this.$t('Email') } },
                { price: { label: this.$t('Price') } },
                { price_unit: { label: this.$t('Price unit') } },
                { schedule: { label: this.$t('Schedule') } },
                { kid: { label: this.$t('Kid') } },
                { payment_enabled: { label: this.$t('Payment enabled') } },
                { paid: { label: this.$t('Paid') } },
                { phone: { label: this.$t('Phone') } },
                { activity: { label: this.$t('Activity') } },
              ]"
            >
              <template #cell(payment_enabled)="data">
                <feather-icon
                  v-if="data.value"
                  style="color: #6bc4c5; display: inline; margin-top: 0.75em"
                  size="16"
                  icon="CheckCircleIcon"
                  class=""
                />
              </template>
              <template #cell(subscription)="data">
                <feather-icon
                  v-if="data.value"
                  style="color: #6bc4c5; display: inline; margin-top: 0.75em"
                  size="16"
                  icon="CheckCircleIcon"
                  class=""
                />
              </template>
              <template #cell(paid)="data">
                <feather-icon
                  v-if="data.value"
                  style="color: #6bc4c5; display: inline; margin-top: 0.75em"
                  size="16"
                  icon="CheckCircleIcon"
                  class=""
                />
              </template>
            </b-table>
          </div>
          <div v-else class="no-results">
            <h3>{{ $t('No results') }}</h3>
          </div>
        </template>
        <div v-else class="row">
          <div class="col-md-4">
            <p class="feature-text">{{ $t('blocked_feat_bookings_1') }}</p>
            <p class="feature-text">{{ $t('blocked_feat_bookings_2') }}</p>
          </div>
          <div class="col-md-8">
            <img
              src="@/assets/images/screenshots/bookings.webp"
              alt="Screenshot of the bookings page"
              class="screenshot"
            />
          </div>
        </div>
      </div>
      <div v-if="!hasAccess" class="card-footer footer-shadow">
        <router-link to="/subscribe" class="btn btn-toddl">
          {{ $t('upgrade_plan_cta') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BTable } from 'bootstrap-vue'

import userCan from '@/libs/user-can'
import BookingService from '@/services/BookingService'

export default {
  name: 'Bookings',
  components: {
    BTable,
    BLink,
  },
  data() {
    return {
      BookingService: new BookingService(),

      bookings: [],
      search: null,
    }
  },
  computed: {
    hasAccess: function () {
      return userCan(this.$store.state.auth.user.plan, 'bookings')
    },
  },
  mounted() {
    if (this.hasAccess) {
      this.getBookings()
    }
  },
  methods: {
    async getBookings() {
      this.bookings = []
      const bookings = await this.BookingService.index()

      this.bookings = bookings.map(booking => {
        return {
          date:
            booking.created_at.split('T')[0] + ' ' + booking.created_at.split('T')[1].slice(0, 5),
          price: booking.display_price,
          price_unit: booking.price_unit,
          activity: booking.activity.name,
          schedule: booking.schedule,
          kid: booking.kid.name,
          payment_enabled: booking.stripe_charge_id,
          paid: booking.paid,
          subscription: booking.subscription,
          parent: booking.client ? booking.client.name : booking.contact?.full_name,
          phone: booking.client ? booking.client.phone : booking.contact?.phone,
          email: booking.client ? booking.client.email : booking.contact?.email,
          manage: booking.id,
        }
      })
    },
  },
}
</script>

<style scoped>
.screenshot {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 10;
}

.feature-text {
  font-size: 1.2rem;
  color: #333;
}
</style>
