var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bookings"},[_c('div',{staticClass:"card full-page"},[_c('div',{staticClass:"card-body inner-scroll"},[(_vm.hasAccess)?[_c('div',{staticClass:"feat-desc"},[_c('p',[_vm._v(_vm._s(_vm.$t('desc_feat_bookings_1')))])]),(_vm.bookings.length > 0)?_c('div',{staticClass:"bookings-table"},[_c('div',{staticClass:"filter-table float-right w-25 mb-2"},[_c('feather-icon',{staticClass:"mr-50 float-left",staticStyle:{"color":"#6bc4c5","display":"inline","margin-top":"0.75em"},attrs:{"size":"16","icon":"SearchIcon"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control inline",staticStyle:{"display":"inline","width":"85% !important"},attrs:{"type":"text","placeholder":_vm.$t('Search')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})],1),_c('b-table',{staticClass:"position-relative",attrs:{"striped":"","hover":"","responsive":"","filter":_vm.search,"items":_vm.bookings,"fields":[
              { date: { label: this.$t('Date') } },
              { parent: { label: this.$t('Parent') } },
              { email: { label: this.$t('Email') } },
              { price: { label: this.$t('Price') } },
              { price_unit: { label: this.$t('Price unit') } },
              { schedule: { label: this.$t('Schedule') } },
              { kid: { label: this.$t('Kid') } },
              { payment_enabled: { label: this.$t('Payment enabled') } },
              { paid: { label: this.$t('Paid') } },
              { phone: { label: this.$t('Phone') } },
              { activity: { label: this.$t('Activity') } } ]},scopedSlots:_vm._u([{key:"cell(payment_enabled)",fn:function(data){return [(data.value)?_c('feather-icon',{staticStyle:{"color":"#6bc4c5","display":"inline","margin-top":"0.75em"},attrs:{"size":"16","icon":"CheckCircleIcon"}}):_vm._e()]}},{key:"cell(subscription)",fn:function(data){return [(data.value)?_c('feather-icon',{staticStyle:{"color":"#6bc4c5","display":"inline","margin-top":"0.75em"},attrs:{"size":"16","icon":"CheckCircleIcon"}}):_vm._e()]}},{key:"cell(paid)",fn:function(data){return [(data.value)?_c('feather-icon',{staticStyle:{"color":"#6bc4c5","display":"inline","margin-top":"0.75em"},attrs:{"size":"16","icon":"CheckCircleIcon"}}):_vm._e()]}}],null,false,2466161588)})],1):_c('div',{staticClass:"no-results"},[_c('h3',[_vm._v(_vm._s(_vm.$t('No results')))])])]:_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('p',{staticClass:"feature-text"},[_vm._v(_vm._s(_vm.$t('blocked_feat_bookings_1')))]),_c('p',{staticClass:"feature-text"},[_vm._v(_vm._s(_vm.$t('blocked_feat_bookings_2')))])]),_vm._m(0)])],2),(!_vm.hasAccess)?_c('div',{staticClass:"card-footer footer-shadow"},[_c('router-link',{staticClass:"btn btn-toddl",attrs:{"to":"/subscribe"}},[_vm._v(" "+_vm._s(_vm.$t('upgrade_plan_cta'))+" ")])],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8"},[_c('img',{staticClass:"screenshot",attrs:{"src":require("@/assets/images/screenshots/bookings.webp"),"alt":"Screenshot of the bookings page"}})])}]

export { render, staticRenderFns }